export const getErrorMessage = (error) =>
  error?.message
    ? error.message
    : error?.error && typeof error.error === "string"
    ? error.error
    : typeof error === "string"
    ? error
    : error[0]?.message
    ? error[0]?.message
    : "Something went wrong!";

export function checkKeys(object) {
  let keys = [
    "name",
    "tagLine",
    "qualification",
    "experience",
    "languagesKnown",
    "location",
    "affiliatedOrg",
    "specialities",
    "shortBio",
    "lastHeldPosition",
  ];
  let is_missing = false;
  for (const key of keys) {
    if (!object[key]) {
      is_missing = true;
    }
  }
  return is_missing;
}

// Function to change Base64 to File type
export function dataURLtoFile(dataurl, filename) {
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export const TextType = [
  { label: "Select Type", value: "", disabled: true },
  {
    label: "Question",
    value: "Question",
  },
  { label: "Answer", value: "Answer" },
  { label: "Statement", value: "Statement" },
  { label: "Order", value: "Order" },
];
