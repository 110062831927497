import React, { useEffect } from "react";
import {
  Header3,
  HeadingContainer,
  CardWrapper,
  ContentContainer,
} from "../../../styles/component/style";
import { PrimaryCTAButton } from "../../common/Buttons";
import { Formik } from "formik";
import { LoginEmailValidationSchema } from "../../../helpers/validationSchema";
import FormField from "../../common/FormField/FormField";
import _ from "lodash";
import TranscriberService from "../../../services/TranscriberService";
import { useSnackbar } from "notistack";
import { navigate } from "@reach/router";
import { getErrorMessage } from "../../../helpers/functions";
import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import useUser from "../../../hooks/useUser";
import useLoader from "../../../hooks/useLoader";

const Login = ({
  heading = "Login",
  inputs = [],
  submitLabel = "Login",
  currentTab,
  setCurrentTab,
  disableSubmit = false,
  location,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { setToken } = useUser();
  const { state } = location;
  const { setLoader } = useLoader();

  useEffect(() => {
    if (state?.payload?.mobile) {
      setCurrentTab("mobile");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.payload?.mobile]);

  async function onFormSubmit(values) {
    try {
      setLoader({ state: true });
      const payloadType = currentTab;
      let payload = _.pick(values, [currentTab, "password"]);
      if (payloadType === "mobile") {
        delete payload.password;
      }
      const response = await TranscriberService.login(payload);
      if (response.token && payloadType === "email") {
        setToken(response.token);
        enqueueSnackbar("Login successful", {
          variant: "success",
        });
        navigate("/transcriber/cases?caseType=mediation");
      } else {
        enqueueSnackbar("OTP sent successfully", {
          variant: "success",
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  /**
   * @description Function to login with google
   * @param {*} res
   */

  return (
    <Formik
      initialValues={{
        email: state?.payload?.email || "",
        mobile: state?.payload?.mobile || "",
        password: state?.payload?.password || "",
      }}
      enableReinitialize
      onSubmit={onFormSubmit}
      validationSchema={LoginEmailValidationSchema}
      validateOnBlur
      validateOnChange
    >
      {({
        values,
        handleChange,
        errors,
        handleSubmit,
        touched,
        handleBlur,
        setFieldValue,
      }) => (
        <CardWrapper onSubmit={handleSubmit}>
          <HeadingContainer>
            <Header3>{heading}</Header3>
          </HeadingContainer>
          <ContentContainer>
            {currentTab === "mobile" ? (
              <Heading>Enter your Registered Mobile Number</Heading>
            ) : (
              ""
            )}
            {inputs
              .filter((input) =>
                currentTab === "mobile" ? input.name === currentTab : true
              )
              .map((input, index) => {
                return (
                  <FormField
                    key={index}
                    {...{
                      touched,
                      errors,
                      input,
                      values,
                      handleChange,
                      handleBlur,
                      setFieldValue,
                    }}
                  />
                );
              })}
          </ContentContainer>

          <div className="pt20 pb10">
            <PrimaryCTAButton
              type="submit"
              onClick={handleSubmit}
              disabled={disableSubmit}
            >
              {submitLabel}
            </PrimaryCTAButton>
          </div>
        </CardWrapper>
      )}
    </Formik>
  );
};

export default Login;

const Heading = styled.div`
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontRegular};
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  margin-top: 19px;
  margin-bottom: 37px;
  @media (max-width: 500px) {
    margin-top: 10px;
    margin-bottom: 15px;
  }
`;
