import React, { useState, useEffect } from "react";
import styled from "styled-components";
import TimeBox from "./TimeBox";
import {
  FastForwardSharp,
  FastRewindSharp,
  PlayArrow,
  Forward10,
  VolumeOff,
  VolumeUp,
  PauseOutlined,
  PictureInPicture,
  CheckBox,
  CheckBoxOutlineBlank,
} from "@material-ui/icons";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import { Tooltip } from "@material-ui/core";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

const PlayerControls = ({
  playMedia,
  isPlaying,
  isMute,
  skipBackward,
  skipForward,
  rollback,
  currentTime,
  duration,
  handleMuteVolume,
  promptSetCurrentTime,
  pictureInPicture,
  mediaType,
  playMediaFile,
  pauseMedia,
}) => {
  const [state, setState] = useState(false);

  useEffect(() => {
    if (state) {
      listenContinuously();
    } else {
      SpeechRecognition.stopListening();
    }
  }, [state]);

  const { interimTranscript, finalTranscript, transcript } =
    useSpeechRecognition();

  const lastCommand = transcript.split(" ");
  const command = lastCommand[lastCommand.length - 1];

  useEffect(() => {
    if (command === "play" || command === "Play") {
      playMediaFile();
    } else if (command === "stop" || command === "Stop") {
      skipBackward();
    } else if (command === "forward" || command === "Forward") {
      skipForward();
    } else if (command === "rewind" || command === "Rewind") {
      skipBackward();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transcript]);

  useEffect(() => {
    if (finalTranscript !== "") {
      // console.log("Got final result:", finalTranscript);
    }
  }, [interimTranscript, finalTranscript]);

  const listenContinuously = () => {
    SpeechRecognition.startListening({
      continuous: true,
      language: "en-GB",
    });
  };

  return (
    <>
      <Container>
        <DisplayContainer>
          <TimeBox
            promptSetCurrentTime={promptSetCurrentTime}
            currentTime={currentTime}
            duration={duration}
          />
          <ButtonPlayer
            value="seek backward by a set interval: alt r"
            title="seek backward by a set interval: alt r"
            onClick={() => rollback()}
          >
            <Forward10 />
          </ButtonPlayer>
          <ButtonPlayer
            value="seek backward: alt j"
            title="seek backward: alt j"
            onClick={() => skipBackward()}
          >
            <FastRewindSharp />
          </ButtonPlayer>
          <ButtonPlayer
            value="Play/Pause: alt k"
            title="Play/Pause: alt k"
            onClick={() => playMedia()}
          >
            {isPlaying ? <PauseOutlined /> : <PlayArrow />}
          </ButtonPlayer>
          <ButtonPlayer
            value="seek forward: alt l"
            title="seek forward: alt l"
            onClick={() => skipForward()}
          >
            <FastForwardSharp />
          </ButtonPlayer>
          <ButtonPlayer
            value="Toggle Sound"
            title="Toggle Sound"
            onClick={() => handleMuteVolume()}
          >
            {isMute ? <VolumeOff /> : <VolumeUp />}
          </ButtonPlayer>
          <Tooltip title={"Voice Command"} placement="right">
            <ButtonPlayer
              onClick={() => (state ? setState(false) : setState(true))}
            >
              {state ? <CheckBox /> : <CheckBoxOutlineBlank />}
            </ButtonPlayer>
          </Tooltip>
          {"pictureInPictureEnabled" in document && mediaType === "video" ? (
            <ButtonPlayer
              value="Picture-in-picture"
              title="Picture-in-picture"
              onClick={() => pictureInPicture()}
            >
              <PictureInPicture />
            </ButtonPlayer>
          ) : null}
        </DisplayContainer>
      </Container>
    </>
  );
};

export default PlayerControls;

const Container = styled.div`
  display: inline-block;
  margin-right: 0.5em;
  margin-bottom: 0.5em;
`;

const DisplayContainer = styled.div`
  display: flex;
  border-radius: 5px;
  width: 100%;
  @media ${theme.breakpoints.sm_down} {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 3px;
  }
`;

const ButtonPlayer = styled.button`
  width: 38px;
  height: 38px;
  border-radius: 4px;
  padding: 0.5em;
  border: 0;
  color: white;
  background: ${COLORS.BTN_GREEN};
  font-size: 1em;
  cursor: pointer;
  margin-right: 0.3rem;
  margin-top: 0.3rem;

  &:hover {
    background: ${COLORS.BTN_GREEN};
  }
`;

export const TimeBoxs = styled.div`
  display: inline-block;
  text-align: center;
  border-radius: 5px;
  line-height: 38px;
  padding: 0 2em;
  margin-right: 0.3rem;
  margin-top: 0.2rem;
  background-color: ${COLORS.INPUT_GRAY};
  @media ${theme?.breakpoints?.sm_down} {
    width: 100%;
  }
`;
