import React from "react";
import { Router } from "@reach/router";
import LoginPage from "./components/pages/LoginPage";
import Cases from "./components/pages/Cases";
import MyCases from "./components/pages/MyCases/";
import DashboardScreens from "./components/pages/DashboardScreens";
import LoginPath from "./components/pages/LoginPage/LoginPath";
import NotFoundPage from "./components/pages/NotFoundPage";
import TimeSlideTranscription from "./components/internal/TimeSlideTranscript/index";
import LiveTranscription from "./components/internal/TimeSlideTranscript/LiveTranscription";
import RecordingTranscription from "./components/internal/TimeSlideTranscript/RecordingLive";
import CrossExamination from "./components/internal/CrossExamination/index";
import CrossExaminationTranscript from "./components/internal/CrossExamination/CrossExaminationTranscripts";

const AppRouter = () => {
  return (
    <Router>
      <NotFoundPage default />
      <NotFoundPage
        errorCode="Error Code : 500"
        errorMessage="The server encountered an internal error or misconfiguration and was unable to complete your request."
        path="/internal-error"
      />
      <LoginPath path="/">
        <NotFoundPage default />
        <LoginPage path="/" />
      </LoginPath>
      <DashboardScreens path="/transcriber">
        <NotFoundPage default />
        <Cases path="/cases">
          <NotFoundPage default />
          <MyCases path="/" />
          <TimeSlideTranscription path="/:id/transcription" />
          <LiveTranscription path="/:id/livetranscription" type="arbitration" />
          <RecordingTranscription path="/:id/recording-transcription" />
          <CrossExamination path="/:id/cross-examination" />
          <CrossExaminationTranscript path="/:id/cross-examination-transcript" />
        </Cases>
      </DashboardScreens>
    </Router>
  );
};

export default AppRouter;
