import API from "../api/manager";
import config from "../api/config";

const profile = () => {
  return API({
    method: "GET",
    url: config.urls.auth.profile,
  });
};

const login = (data) => {
  return API(
    {
      method: "POST",
      url: config.urls.transcriber.login,
      data,
    },
    false
  );
};

const signUp = (data) => {
  return API(
    {
      method: "POST",
      url: config.urls.transcriber.transcriber,
      data,
    },
    false
  );
};

export default {
  profile,
  login,
  signUp,
};
