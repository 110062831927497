import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import _ from "lodash";
import StyledAppBar from "./StyledAppBar";
import Sidebar from "./Sidebar";
import { TranslaterContext } from "../../../providers/TranslaterProvider";
import useCartCount from "../../../hooks/useCartCount";
import { useLocation } from "@reach/router";
import useUser from "../../../hooks/useUser";
import TranscriberService from "../../../services/TranscriberService";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100vw",
    overflow: "hidden",
  },
  content: {
    flexGrow: 1,
    marginTop: 74,
    // eslint-disable-next-line no-useless-computed-key
    ["@media (max-width:600px)"]: {
      width: "100%",
    },
  },
}));

export function Header({
  children,
  showSidebar = false,
  selectedItem,
  NavPages = false,
  hideLinks = false,
  LandingPage = false,
  selectedSubItem = "",
}) {
  const classes = useStyles();
  const { userObject } = useUser();
  const { translaterOpen } = useContext(TranslaterContext);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { triggerCount, setTriggerCount, setStats, stats } = useCartCount();

  useEffect(() => {
    if (!_.isEmpty(userObject) && triggerCount) {
      TranscriberService.caseStatsTranscribers("").then(
        (res) => {
          setStats(res);
        },
        (err) => {
          console.log(err);
          setTriggerCount(false);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userObject, triggerCount]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <StyledAppBar NavPages={NavPages} {...{ hideLinks, LandingPage }} />
      {showSidebar && (
        <Sidebar
          {...{ selectedItem, stats, selectedSubItem }}
          selectedSubItem={
            searchParams.get("caseType")
              ? searchParams.get("caseType")
              : selectedSubItem
          }
        />
      )}
      <main
        className={classes.content}
        style={translaterOpen ? { marginTop: `40px !important` } : {}}
      >
        {children}
      </main>
    </div>
  );
}
