import useUser from "./useUser";
import { useEffect } from "react";
import { navigate } from "@reach/router";

export default function useIfLoggedIn() {
  const { token } = useUser();
  // const location = useLocation();

  // useEffect(() => {
  //   if (
  //     !_.isEmpty(userObject) &&
  //     userObject.emailVerified &&
  //     !checkKeys(userObject) &&
  //     !EXCLUDE_URL.some((path) => location?.pathname?.includes(path))
  //   ) {
  //     navigate("/transcriber/cases?caseType=mediation");
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [userObject]);
  useEffect(() => {
    if (token) {
      navigate("/transcriber/cases?caseType=mediation");
    }
  });
}
