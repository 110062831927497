import React, { Fragment } from "react";
import styled, { css } from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";

const ActionBar = ({
  actions = [],
  breadcrumbs = [],
  outlined = false,
  onBreadcrumbClick = () => {},
}) => {
  return (
    <ActionBarContainer>
      <Breadcrumbs>
        {breadcrumbs.map((breadcrumb, index) => (
          <Fragment key={`breadcrumb-${breadcrumb}-${index}`}>
            <Breadcrumb onClick={() => onBreadcrumbClick(breadcrumbs[index])}>
              {breadcrumb === "Generic Meeting Link" ? (
                <div style={{ fontSize: "16px" }}>{breadcrumb}</div>
              ) : breadcrumb === "Recordings" ? (
                <div style={{ fontSize: "16px" }}>{breadcrumb}</div>
              ) : (
                breadcrumb
              )}
            </Breadcrumb>
            {index < breadcrumbs.length - 1 && <Breadcrumb>{">"}</Breadcrumb>}
          </Fragment>
        ))}
      </Breadcrumbs>
      <ActionButtonContainer>
        {actions.map((action, index) => (
          <ActionButton
            disabled={action.disabled}
            outlined={action.outlined}
            hidden={action.hidden}
            key={`action-${action.text}-${index}`}
            {...action.props}
          >
            {action.text}
          </ActionButton>
        ))}
      </ActionButtonContainer>
    </ActionBarContainer>
  );
};

export default ActionBar;

const ActionBarContainer = styled.div`
  border-bottom: 1px solid ${COLORS.BORDER_GREY};
  padding: 0px 53px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
`;

const ActionButton = styled.button`
  border-radius: 4px;
  background-color: ${({ outlined }) =>
    outlined ? COLORS.PRIMARY_WHITE : COLORS.BTN_GREEN};
  color: ${({ outlined }) =>
    outlined ? COLORS.BTN_GREEN : COLORS.PRIMARY_WHITE};
  border: ${(outlined) => (outlined ? `1px solid ${COLORS.BTN_GREEN}` : 0)};
  width: 161px;
  height: 30px;
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 14px;
  text-align: center;
  outline: none;
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.6;
    `}
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  margin-left: 23px;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Breadcrumbs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Breadcrumb = styled.div`
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  margin-right: 4px;
  cursor: pointer;
  color: ${COLORS.COLOR_DARK};
  user-select: none;
`;
