import React, { useState } from "react";
import { Center } from "../../../styles/component/style";
import { SignupContainer } from "./styles";
import { Header } from "../../internal/Header/Header";
import Login from "../../internal/Login/Login";
import { useEffect } from "react";
import _ from "lodash";
import { Helmet } from "react-helmet";

const passwords = {
  name: "password",
  label: "Password",
  type: "password",
  placeholder: `Enter your Password`,
  required: true
};

const Index = ({ location = {} }) => {
  const [currentTab, setCurrentTab] = useState("email");
  const [inputs, setInputs] = useState([
    {
      name: "email",
      label: "Registered Email",
      type: "email",
      placeholder: `Enter Your Email`
    },
    passwords
  ]);

  useEffect(() => {
    if (currentTab) {
      setInputs([
        {
          name: currentTab,
          label: `Registered ${_.capitalize(currentTab)}`,
          type: currentTab === "mobile" ? "number" : currentTab,
          placeholder: `Enter Your ${_.capitalize(currentTab)}`,
          required: true
        },
        passwords
      ]);
    }
  }, [currentTab]);
  return (
    <Header>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <title>
          JustAct Transcriber Portal - Online Dispute Resolution in India
        </title>
        <meta name="description" content="Welcome to JustAct Transcriber Portal." />
        <meta
          name="keywords"
          content="JustAct Transcriber Portal, Online Dispute Resolution in India, Dispute Resolution Online, Alternative Dispute Resolution, JustAct"
        />
      </Helmet>
      <Center>
        <SignupContainer>
          <Login
            heading={"TRANSCRIBER LOGIN"}
            inputs={inputs}
            {...{ currentTab, setCurrentTab, location }}
          />
        </SignupContainer>
      </Center>
    </Header>
  );
};

export default Index;
