import API from "../api/manager";
import config from "../api/config";

const profile = () => {
  return API({
    method: "GET",
    url: config.urls.auth.profile,
  });
};
const login = (data) => {
  return API(
    {
      method: "POST",
      url: config.urls.transcriber.login,
      data,
    },
    false,
  );
};

const getCasesTranscribers = (query) => {
  return API({
    method: "GET",
    url: config.urls.transcriber.transcriberCases + query,
  });
};

const caseStatsTranscribers = (params) => {
  return API({
    method: "GET",
    url: config.urls.transcriber.transcriberCasesStats + params,
  });
};

const updateTranscriptionData = (data, id) => {
  return API({
    method: "PUT",
    url: `${config.urls.case.updateTranscriptionData}/${id}`,
    data,
  });
};

const getLiveTranscription = (transcriptId) => {
  return API({
    method: "GET",
    url: `${config.urls.case.getLiveTranscription}/${transcriptId}`,
  });
};

const getLiveTranscriptionData = (id) => {
  return API({
    method: "GET",
    url: `${config.urls.case.getLiveTranscriptionData}/${id}`,
  });
};

const updateLiveTranscriptionData = (data, id) => {
  return API({
    method: "PUT",
    url: `${config.urls.case.updateLiveTranscriptionData}/${id}`,
    data,
  });
};

const downloadTranscripts = (data) => {
  return API({
    method: "POST",
    url: `/downloadTranscripts`,
    data,
  });
};

const getMeetingAudioTranscript = (meetingAudioId) => {
  return API({
    method: "GET",
    url: `${config.urls.case.getMeetingAudioTranscript}/${meetingAudioId}`,
  });
};

const updateAudioTranscriptData = (data, meetingAudioId) => {
  return API({
    method: "PUT",
    url: `${config.urls.case.updateAudioTranscriptData}/${meetingAudioId}`,
    data,
  });
};

const updateXExamTranscript = (data, meetingId) => {
  return API({
    method: "PUT",
    url: `${config.urls.transcription.updateXExamTranscript}/${meetingId}`,
    data,
  });
};

const updateXExamTranscriptDoc = (data, transcriptId) => {
  return API({
    method: "PUT",
    url: `${config.urls.transcription.updateXExamTranscriptDoc}/${transcriptId}`,
    data,
  });
};

const submitLiveTranscription = (meetingId) => {
  return API({
    method: "POST",
    url: `${config.urls.submit.submitLiveTranscription}/${meetingId}`,
  });
};

const submitVideoRecTranscription = (transcriptId) => {
  return API({
    method: "POST",
    url: `${config.urls.submit.submitVideoRecTranscription}/${transcriptId}`,
  });
};

const submitAudioRecTranscription = (meetingAudioId) => {
  return API({
    method: "POST",
    url: `${config.urls.submit.submitAudioRecTranscription}/${meetingAudioId}`,
  });
};

const GetMeetingAccesscases = (caseId) => {
  return API({
    method: "GET",
    url: `${config.urls.meeting.mettingcase}/${caseId}`,
  });
};

export default {
  getCasesTranscribers,
  caseStatsTranscribers,
  profile,
  login,
  updateTranscriptionData,
  getLiveTranscription,
  getLiveTranscriptionData,
  updateLiveTranscriptionData,
  downloadTranscripts,
  getMeetingAudioTranscript,
  updateAudioTranscriptData,
  updateXExamTranscript,
  updateXExamTranscriptDoc,
  submitLiveTranscription,
  submitVideoRecTranscription,
  submitAudioRecTranscription,
  GetMeetingAccesscases,
};
